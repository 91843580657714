import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入自定义文件
import './assets/common/css/base.css'

// 引入第三方插件
import VueSmoothScroll from "vue2-smooth-scroll";
// import "lib-flexible-computer";

createApp(App).use(store).use(router).mount('#app')
