import { resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // 重定向
  {
    path: '/',
    redirect: '/home'
  },
  // 网站首页
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '宁讯科技-专注无线传输产品及方案'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/Home`))
    // component: () => import('../views/levelone/Home')
  },
  // 移动端页面
  {
    path: '/mHome',
    name: 'mHome',
    meta: {
      title: '宁讯科技-专注无线传输产品及方案'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/Home`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustry',
    name: 'mProductIndustry',
    meta: {
      title: '宁讯科技-工业覆盖传输产品系列'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/ProductIndustry`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryBaseStation',
    name: 'mProductIndustryBaseStation',
    meta: {
      title: '宁讯科技-工业覆盖传输产品系列-无线快速切换基站'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/BaseStation`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryExplosionBaseStation',
    name: 'mProductIndustryExplosionBaseStation',
    meta: {
      title: '宁讯科技-工业覆盖传输产品系列-防爆型无线快速切换基站'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/ExplosionBaseStation`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryWirelessPoint',
    name: 'mProductIndustryWirelessPoint',
    meta: {
      title: '宁讯科技-工业覆盖传输产品系列-吸顶式室内无线接入点'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/WirelessPoint`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryNetworkBridge',
    name: 'mProductIndustryNetworkBridge',
    meta: {
      title: '宁讯科技-工业覆盖传输产品系列-高带宽无线传输网桥'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/NetworkBridge`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryWirelessUnit',
    name: 'mProductIndustryWirelessUnit',
    meta: {
      title: '宁讯科技-工业车载传输系列-车载无线单元'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/WirelessUnit`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryRoutingGateway',
    name: 'mProductIndustryRoutingGateway',
    meta: {
      title: '宁讯科技-工业车载传输系列-4/5G路由网关'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/RoutingGateway`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryProcessor',
    name: 'mProductIndustryProcessor',
    meta: {
      title: '宁讯科技-工业车载传输系列-工业级32位通信处理器'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/Processor`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryClient',
    name: 'mProductIndustryClient',
    meta: {
      title: '宁讯科技-工业车载传输系列-双频无线客户端'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/Client`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryControlle',
    name: 'mProductIndustryControlle',
    meta: {
      title: '宁讯科技-相关无线覆盖传输产品规格选型-无线接入控制器'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/Controller`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryExchange',
    name: 'mProductIndustryExchange',
    meta: {
      title: '宁讯科技-相关无线覆盖传输产品规格选型-工业级千兆以太交换机'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/Exchange`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryCloudGateway',
    name: 'mProductIndustryCloudGateway',
    meta: {
      title: '宁讯科技-相关无线覆盖传输产品规格选型-智能云网关'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/CloudGateway`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryCloudPlatform',
    name: 'mProductIndustryCloudPlatform',
    meta: {
      title: '宁讯科技-无线系统管理平台-云智能维护平台'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/CloudPlatform`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryMiddlePlatform',
    name: 'mProductIndustryMiddlePlatform',
    meta: {
      title: '宁讯科技-无线系统管理平台-智能接入中间件平台'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/MiddlePlatform`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryPOE',
    name: 'mProductIndustryPOE',
    meta: {
      title: '宁讯科技-无线选配附件系列-POE供电模块'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/POE`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustrySingleAntenna',
    name: 'mProductIndustrySingleAntenna',
    meta: {
      title: '宁讯科技-无线选配附件系列-单频蘑菇天线'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/SingleAntenna`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryDoubleAntenna',
    name: 'mProductIndustryDoubleAntenna',
    meta: {
      title: '宁讯科技-无线选配附件系列-双频蘑菇天线'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/DoubleAntenna`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryCabinet',
    name: 'mProductIndustryCabinet',
    meta: {
      title: '宁讯科技-智能无线保管柜系列-智能无线保管柜'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/Cabinet`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductIndustryModule',
    name: 'mProductIndustryModule',
    meta: {
      title: '宁讯科技-智能无线保管柜系列-保管柜模组设备'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/product/Module`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductCar',
    name: 'mProductCar',
    meta: {
      title: '宁讯科技-无线车载传输产品系列'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/ProductCar`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductAbout',
    name: 'mProductAbout',
    meta: {
      title: '宁讯科技-相关无线覆盖传输产品规格选型'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/ProductAbout`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductPlatform',
    name: 'mProductPlatform',
    meta: {
      title: '宁讯科技-无线系统管理平台'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/ProductPlatform`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductMatch',
    name: 'mProductMatch',
    meta: {
      title: '宁讯科技-无线选配附件系列'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/ProductMatch`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mProductCabinet',
    name: 'mProductCabinet',
    meta: {
      title: '宁讯科技-智能无线保管柜系列'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/ProductCabinet`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionVideo',
    name: 'mSolutionVideo',
    meta: {
      title: '宁讯科技-视频传输应用'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/SolutionVideo`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionData',
    name: 'mSolutionData',
    meta: {
      title: '宁讯科技-数据采集应用'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/SolutionData`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionRobot',
    name: 'mSolutionRobot',
    meta: {
      title: '宁讯科技-移动机器无线覆盖传输'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/SolutionRobot`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionCabinet',
    name: 'mSolutionCabinet',
    meta: {
      title: '宁讯科技-智能无线保管柜'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/SolutionCabinet`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionCabinet',
    name: 'mSolutionCabinet',
    meta: {
      title: '宁讯科技-智能无线保管柜'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/SolutionCabinet`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionMonitoringMore',
    name: 'mSolutionMonitoringMore',
    meta: {
      title: '宁讯科技-无线视频传输应用（视距、固定点）'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Monitoring`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionCityMore',
    name: 'mSolutionCityMore',
    meta: {
      title: '宁讯科技-移动MESH无线视频覆盖传输（非视距、移动点）'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/City`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionFactoryMore',
    name: 'mSolutionFactoryMore',
    meta: {
      title: '宁讯科技-工业无线数传'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Factory`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionWarehouseMore',
    name: 'mSolutionWarehouseMore',
    meta: {
      title: '宁讯科技-物流无线覆盖传输'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Warehouse`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionRobotMore',
    name: 'mSolutionRobotMore',
    meta: {
      title: '宁讯科技-AGV移动机器人覆盖漫游传输'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Robot`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionElectricityMore',
    name: 'mSolutionElectricityMore',
    meta: {
      title: '宁讯科技-电力巡检覆盖传输'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Electricity`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionPipeMore',
    name: 'mSolutionPipeMore',
    meta: {
      title: '宁讯科技-管廊覆盖及定位'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Pipe`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSolutionCabinetMore',
    name: 'mSolutionCabinetMore',
    meta: {
      title: '宁讯科技-智能无线保管柜'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/solution/Cabinet`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSupport',
    name: 'mSupport',
    meta: {
      title: '宁讯科技-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/Support`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mAssets',
    name: 'mAssets',
    meta: {
      title: '宁讯科技-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/Assets`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mCase',
    name: 'mCase',
    meta: {
      title: '宁讯科技-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/support/Case`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSupportBus',
    name: 'mSupportBus',
    meta: {
      title: '宁讯科技-杭州BRT快速公交无线视频传输项目'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelthree/Bus`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSupportOilfield',
    name: 'mSupportOilfield',
    meta: {
      title: '宁讯科技-青海油田无线传输监控项目'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelthree/Oilfield`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mSupportPark',
    name: 'mSupportPark',
    meta: {
      title: '宁讯科技-云龙公园无线视频监控传输项目'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelthree/Park`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mGuide',
    name: 'mGuide',
    meta: {
      title: '宁讯科技-产品安装指导'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/support/Guide`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mLogin',
    name: 'mLogin',
    meta: {
      title: '宁讯科技-产品技术文档下载'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/leveltwo/support/Login`))
    // component: () => import('../views/Phone')
  },
  {
    path: '/mAbout',
    name: 'mAbout',
    meta: {
      title: '宁讯科技-关于我们'
    },
    component: () => require.ensure([], (require) => require(`../views/mobile/levelone/About`))
    // component: () => import('../views/Phone')
  },

  // 产品中心
  {
    path: '/product',
    name: 'Product',
    meta: {
      title: '宁讯科技-产品中心'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/Product`))
    // component: () => import('../views/levelone/Product')
  },
  {
    path: '/storageCabinet',
    name: 'StorageCabinet',
    meta: {
      title: '宁讯科技-产品中心'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/StorageCabinet`))
    // component: () => import('../views/levelone/StorageCabinet')
  },
  {
    path: '/platform',
    name: 'Platform',
    meta: {
      title: '宁讯科技-产品中心'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/Platform`))
    // component: () => import('../views/levelone/Platform')
  },
  {
    path: '/productCenter',
    name: 'ProductCenter',
    meta: {
      title: '宁讯科技-产品中心'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/ProductCenter`))
    // component: () => import('../views/levelone/ProductCenter')
  },
  // 解决方案
  {
    path: '/solution',
    name: 'Solution',
    meta: {
      title: '宁讯科技-解决方案'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/Solution`))
    // component: () => import('../views/levelone/Solution')
  },
  {
    path: '/dataApplication',
    name: 'DataApplication',
    meta: {
      title: '宁讯科技-解决方案'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/dataApplication`))
    // component: () => import('../views/levelone/dataApplication')
  },
  {
    path: '/agvRobot',
    name: 'AgvRobot',
    meta: {
      title: '宁讯科技-解决方案'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/agvRobot`))
    // component: () => import('../views/levelone/agvRobot')
  },
  // 支持&服务
  {
    path: '/support',
    name: 'Support',
    meta: {
      title: '宁讯科技-支持&服务'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/Support`))
    // component: () => import('../views/levelone/Support')
  },
  {
    path: '/service',
    name: 'Service',
    meta: {
      title: '宁讯科技-支持&服务'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/Service`))
    // component: () => import('../views/levelone/Service')
  },
  // 关于宁讯
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '宁讯科技-关于宁讯'
    },
    component: () => require.ensure([], (require) => require(`../views/levelone/About`))
    // component: () => import('../views/levelone/About')
  },
  // 解决方案-无线视频传输应用（视距、固定点）
  {
    path: '/video',
    name: 'Video',
    meta: {
      title: '宁讯科技-解决方案-无线视频传输应用（视距、固定点）'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Monitoring`))
    // component: () => import('../views/leveltwo/solution/Monitoring')
  },
  // 解决方案-移动MESH无线视频覆盖传输（非视距、移动点）
  {
    path: '/city',
    name: 'City',
    meta: {
      title: '宁讯科技-解决方案-移动MESH无线视频覆盖传输（非视距、移动点）'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/City`))
    // component: () => import('../views/leveltwo/solution/City')
  },
  // 解决方案-工业无线数传
  {
    path: '/factory',
    name: 'Factory',
    meta: {
      title: '宁讯科技-解决方案-工业无线数传'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Factory`))
    // component: () => import('../views/leveltwo/solution/Factory')
  },
  // 解决方案-物流无线覆盖传输
  {
    path: '/warehouse',
    name: 'Warehouse',
    meta: {
      title: '宁讯科技-解决方案-物流无线覆盖传输'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Warehouse`))
    // component: () => import('../views/leveltwo/solution/Warehouse')
  },
  // 解决方案-AGV移动机器人覆盖漫游传输
  {
    path: '/robot',
    name: 'Robot',
    meta: {
      title: '宁讯科技-解决方案-AGV移动机器人覆盖漫游传输'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Robot`))
    // component: () => import('../views/leveltwo/solution/Robot')
  },
  // 解决方案-电力巡检覆盖传输
  {
    path: '/electricity',
    name: 'Electricity',
    meta: {
      title: '宁讯科技-解决方案-电力巡检覆盖传输'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Electricity`))
    // component: () => import('../views/leveltwo/solution/Electricity')
  },
  // 解决方案-管廊覆盖及定位
  {
    path: '/pipe',
    name: 'Pipe',
    meta: {
      title: '宁讯科技-解决方案-管廊覆盖及定位'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Pipe`))
    // component: () => import('../views/leveltwo/solution/Pipe')
  },
  // 解决方案-智能无线保管柜
  {
    path: '/cabinet',
    name: 'Cabinet',
    meta: {
      title: '宁讯科技-解决方案-智能无线保管柜'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/solution/Cabinet`))
    // component: () => import('../views/leveltwo/solution/Cabinet')
  },
  // 支持&服务-工程案例实装
  {
    path: '/engineering',
    name: 'Engineering',
    meta: {
      title: '宁讯科技-支持&服务-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/support/Engineering`))
    // component: () => import('../views/leveltwo/support/Engineering')
  },
  // 支持&服务-产品安装指导
  {
    path: '/guide',
    name: 'Guide',
    meta: {
      title: '宁讯科技-支持&服务-产品安装指导'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/support/Guide`))
    // component: () => import('../views/leveltwo/support/Guide')
  },
  // 支持&服务-宁讯科技客户登陆
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '宁讯科技-支持&服务-宁讯科技客户登陆'
    },
    component: () => require.ensure([], (require) => require(`../views/leveltwo/support/Login`))
    // component: () => import('../views/leveltwo/support/Login')
  },
  // 支持&服务-宁讯科技客户登陆-产品技术文档下载
  {
    path: '/document',
    name: 'Document',
    meta: {
      title: '宁讯科技-支持&服务-宁讯科技客户登陆-产品技术文档下载'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/Document`))
    // component: () => import('../views/levelthree/Document')
  },
  // 支持&服务-工程案例实装
  {
    path: '/caseOne',
    name: 'CaseOne',
    meta: {
      title: '宁讯科技-支持&服务-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/EngineeringCaseOne`))
    // component: () => import('../views/levelthree/EngineeringCaseOne')
  },
  // 支持&服务-工程案例实装
  {
    path: '/caseTwo',
    name: 'CaseTwo',
    meta: {
      title: '宁讯科技-支持&服务-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/EngineeringCaseTwo`))
    // component: () => import('../views/levelthree/EngineeringCaseTwo')
  },
  // 支持&服务-工程案例实装
  {
    path: '/caseThree',
    name: 'CaseThree',
    meta: {
      title: '宁讯科技-支持&服务-工程案例实装'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/EngineeringCaseThree`))
    // component: () => import('../views/levelthree/EngineeringCaseThree')
  },
  // 产品详细页one
  {
    path: '/productOneOne',
    name: 'ProductOneOne',
    meta: {
      title: '宁讯科技-支持&服务-无线快速切换基站'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/one/One`))
    // component: () => import('../views/levelthree/product/one/One')
  },
  {
    path: '/productOneTwo',
    name: 'ProductOneTwo',
    meta: {
      title: '宁讯科技-支持&服务-防爆型无线快速切换基站'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/one/Two`))
    // component: () => import('../views/levelthree/product/one/Two')
  },
  {
    path: '/productOneThree',
    name: 'ProductOneThree',
    meta: {
      title: '宁讯科技-支持&服务-吸顶式室内无线接入点'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/one/Three`))
    // component: () => import('../views/levelthree/product/one/Three')
  },
  {
    path: '/productOneFour',
    name: 'ProductOneFour',
    meta: {
      title: '宁讯科技-支持&服务-高带宽无线传输网桥'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/one/Four`))
    // component: () => import('../views/levelthree/product/one/Four')
  },
  // 产品详细页two
  {
    path: '/productTwoOne',
    name: 'ProductTwoOne',
    meta: {
      title: '宁讯科技-支持&服务-车载无线单元'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/two/One`))
    // component: () => import('../views/levelthree/product/two/One')
  },
  {
    path: '/productTwoTwo',
    name: 'ProductTwoTwo',
    meta: {
      title: '宁讯科技-支持&服务-4/5G路由网关'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/two/Two`))
    // component: () => import('../views/levelthree/product/two/Two')
  },
  {
    path: '/productTwoThree',
    name: 'ProductTwoThree',
    meta: {
      title: '宁讯科技-支持&服务-工业级32位通信处理器'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/two/Three`))
    // component: () => import('../views/levelthree/product/two/Three')
  },
  {
    path: '/productTwoFour',
    name: 'ProductTwoFour',
    meta: {
      title: '宁讯科技-支持&服务-双频无线客户端'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/two/Four`))
    // component: () => import('../views/levelthree/product/two/Four')
  },
  // 产品详细页three
  {
    path: '/productThreeOne',
    name: 'ProductThreeOne',
    meta: {
      title: '宁讯科技-支持&服务-无线接入控制器'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/three/One`))
    // component: () => import('../views/levelthree/product/three/One')
  },
  {
    path: '/productThreeTwo',
    name: 'ProductThreeTwo',
    meta: {
      title: '宁讯科技-支持&服务-工业级千兆以太交换机'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/three/Two`))
    // component: () => import('../views/levelthree/product/three/Two')
  },
  {
    path: '/productThreeThree',
    name: 'ProductThreeThree',
    meta: {
      title: '宁讯科技-支持&服务-智能云网关'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/three/Three`))
    // component: () => import('../views/levelthree/product/three/Three')
  },
  {
    path: '/productThreeFour',
    name: 'ProductThreeFour',
    meta: {
      title: '宁讯科技-支持&服务-无线快速切换基站'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/three/Four`))
    // component: () => import('../views/levelthree/product/three/Four')
  },
  // 产品详细页four
  {
    path: '/productFourOne',
    name: 'ProductFourOne',
    meta: {
      title: '宁讯科技-支持&服务-云智能维护管理平台'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/four/One`))
    // component: () => import('../views/levelthree/product/four/One')
  },
  {
    path: '/productFourTwo',
    name: 'ProductFourTwo',
    meta: {
      title: '宁讯科技-支持&服务-智能接入中间件平台'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/four/Two`))
    // component: () => import('../views/levelthree/product/four/Two')
  },
  {
    path: '/productFourThree',
    name: 'ProductFourThree',
    meta: {
      title: '宁讯科技-支持&服务-智能无线保管柜平台'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/four/Three`))
    // component: () => import('../views/levelthree/product/four/Three')
  },
  {
    path: '/productFourFour',
    name: 'ProductFourFour',
    meta: {
      title: '宁讯科技-支持&服务-WNMS云'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/four/Four`))
    // component: () => import('../views/levelthree/product/four/Four')
  },
  {
    path: '/productFourFive',
    name: 'ProductFourFive',
    meta: {
      title: '宁讯科技-支持&服务-数字孪生'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/four/Five`))
    // component: () => import('../views/levelthree/product/four/Five')
  },
  // 产品详细页five
  {
    path: '/productFiveOne',
    name: 'ProductFiveOne',
    meta: {
      title: '宁讯科技-支持&服务-POE供电模块'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/One`))
    // component: () => import('../views/levelthree/product/five/One')
  },
  {
    path: '/productFiveTwo',
    name: 'ProductFiveTwo',
    meta: {
      title: '宁讯科技-支持&服务-双频全向天线'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Two`))
    // component: () => import('../views/levelthree/product/five/Two')
  },
  {
    path: '/productFiveThree',
    name: 'ProductFiveThree',
    meta: {
      title: '宁讯科技-支持&服务-双频定向天线'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Three`))
    // component: () => import('../views/levelthree/product/five/Three')
  },
  {
    path: '/productFiveFour',
    name: 'ProductFiveFour',
    meta: {
      title: '宁讯科技-支持&服务-PCB定向天线'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Four`))
    // component: () => import('../views/levelthree/product/five/Four')
  },
  {
    path: '/productFiveFive',
    name: 'ProductFiveFive',
    meta: {
      title: '宁讯科技-支持&服务-单频蘑菇天线'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Five`))
    // component: () => import('../views/levelthree/product/five/Five')
  },
  {
    path: '/productFiveSix',
    name: 'ProductFiveSix',
    meta: {
      title: '宁讯科技-支持&服务-双频蘑菇天线'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Six`))
    // component: () => import('../views/levelthree/product/five/Six')
  },
  {
    path: '/productFiveSeven',
    name: 'ProductFiveSeven',
    meta: {
      title: '宁讯科技-支持&服务-工业级馈线'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Seven`))
    // component: () => import('../views/levelthree/product/five/Seven')
  },
  {
    path: '/productFiveEight',
    name: 'ProductFiveEight',
    meta: {
      title: '宁讯科技-支持&服务-超宽频功分器'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Eight`))
    // component: () => import('../views/levelthree/product/five/Eight')
  },
  {
    path: '/productFiveNine',
    name: 'ProductFiveNine',
    meta: {
      title: '宁讯科技-支持&服务-超宽频耦合器'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Nine`))
    // component: () => import('../views/levelthree/product/five/Nine')
  },
  {
    path: '/productFiveTen',
    name: 'ProductFiveTen',
    meta: {
      title: '宁讯科技-支持&服务-馈电智能信号传输优化单元'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/five/Ten`))
    // component: () => import('../views/levelthree/product/five/Ten')
  },
  // 产品详细页six
  {
    path: '/productSixOne',
    name: 'ProductSixOne',
    meta: {
      title: '宁讯科技-支持&服务-智能无线保管柜'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/six/One`))
    // component: () => import('../views/levelthree/product/six/One')
  },
  {
    path: '/productSixTwo',
    name: 'ProductSixTwo',
    meta: {
      title: '宁讯科技-支持&服务-保管柜模组设备'
    },
    component: () => require.ensure([], (require) => require(`../views/levelthree/product/six/Two`))
    // component: () => import('../views/levelthree/product/six/Two')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => { // beforeEach是router的钩子函数，在进入路由前执行
  let flag = navigator.userAgent.match(
    /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i
  );
  console.log(flag)
  if (flag) {
    if (to.path.includes('/m')) {
      document.title = to.meta.title
      next()
    } else {
      let newPath = to.path
      newPath = newPath.replace(/\//g, "/m");
      document.title = to.meta.title
      next(newPath)
    }
  } else {
    if (to.path.includes('/m')) {
      const newPath = to.path.replace(/\/m/, '');
      document.title = to.meta.title
      next(newPath)
    } else {
      document.title = to.meta.title
      next()
    }
  }
  next()
})

export default router
